import React, { ReactElement } from 'react'

import Layout from '@/components/layout'

function Page(): ReactElement {
  return (
    <Layout>
      <h2>Hva er kneplager?</h2>
      <p>
        Kneplager er når en har smerter som sitter i, under, over eller ved siden av kneet. Smertene kan komme fra flere
        ulike strukturer som senefester, muskelbuk, menisk, slimposer, leddbånd eller selve leddet. Smerten kan være
        verkende, brennende eller stikkende.
      </p>

      <p>
        Kneplager er en vanlig årsak til å oppsøke kiropraktor. Om pasienten søker for noe annet enn kneplager er det
        vanlig at man har et problem med kneet også. Kneplager er noe vi ofte behandler og har veldig god erfaring med.
        Det er sjeldent at hovedproblemet til kneplagene er selve kneet. Vanlige årsaker til kneplager er feilbelastning
        på kneledd og da kommer som regel hovedproblemet i hofte eller ankel. Det kan også være stramme lårmuskler eller
        leggmuskler som i sin tur kan være stramme på grunn av leddproblem i ankel eller hofte. Hver behandling varer
        fra 20-30 minutt.
      </p>

      <a href="https://www.kiropraktor-akutten.no/behandlingstilbud/fotsmerter">
        https://www.kiropraktor-akutten.no/behandlingstilbud/fotsmerter
      </a>
      <h2>Hva er fotsmerter?</h2>
      <p>
        Fotsmerter er når en har smerter som sitter i foten. Smertene sitter oftest under foten eller i selve
        ankelleddet. Smertene kan komme fra både muskler, sener, muskelhinner («plantarfaschitt») eller slimposer. Noen
        ganger kommer symptomer i foten fra skiveproblemer i korsrygg, da i form av referert smerte på grunn av
        nerverotsirritasjon.
      </p>

      <p>
        Fotsmerter er et problem Kirorpaktor Akutten Stavanger/Sandnes er godt kjent med å behandle. Kiropraktoren
        behandler fot daglig. Om det ikke er på grunn av smerter i selve foten så behandles fot på grunn av den store
        effekten feilbelastning fra foten har på resten av kroppen. Hver behandling varer fra 20-30 minutt.
      </p>
    </Layout>
  )
}

export default Page
